import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Card,
  CardContent,
  Typography,
}
from '@material-ui/core';

import ReturnTypeDialog from '../../ReturnTypeDialog';
import LineItemReturnInfo from '../../LineItemReturnInfo';

export default function SingleLineItem(props) {
  const { t } = useTranslation(['translation', 'products']);
  const { variant, lineItem, allowedExchangeProducts, setBedspringFeilds, setIsBedspringFormActive, bedspringFeilds } = props;

  const [selectedVariant, setSelectedVariant] = useState(lineItem.exchange_variant);
  const [isRefund, setIsRefund] = useState(lineItem.is_refund);
  const [isExchange, setIsExchange] = useState(lineItem.is_exchange);
  const [isReplacement, setIsReplacement] = useState(lineItem.is_replacement);
  const [coverRequest, setCoverRequest] = useState(lineItem.cover_request);
  const [parts_request, setPartsRequest] = useState(lineItem.parts_request)

  useEffect(() => {
    lineItem.exchange_variant = selectedVariant;
    lineItem.is_refund = isRefund;
    lineItem.is_exchange = isExchange;
    lineItem.treated = false;
    // if(bedspringFeilds) {
    //   setPartsRequest(true)
    // }
  }, [selectedVariant, isRefund, isExchange, lineItem, bedspringFeilds])

  const handleCancel = (e) => {
    lineItem.is_refund = false;
    lineItem.treated = false;
    lineItem.is_replacement = false;
    lineItem.exchange_variant = {};
    lineItem.parts_request = false;
    lineItem.partsRequest = null;
    setIsRefund(false);
    setIsExchange(false);
    setIsReplacement(false);
    setSelectedVariant({});
  }

  const handleRefundItem = ({ reason }) => {
    lineItem.is_refund = true;
    lineItem.treated = false;
    lineItem.return_reason = reason;
    lineItem.exchange_variant = {};
    setIsRefund(true);
    setIsExchange(false);
    setSelectedVariant({});
  }

  const handleExchangeVariant = ({ variant, reason }) => {
    lineItem.is_refund = false;
    lineItem.treated = false;
    lineItem.exchange_variant = variant;
    lineItem.return_reason = reason;
    setIsRefund(false);
    setIsExchange(true);
    setSelectedVariant(variant);
  }

  const handleReplacement = () => {
    lineItem.is_refund = false;
    lineItem.treated = false;
    lineItem.is_replacement = true;
    setIsRefund(false);
    setIsExchange(false);
    setIsReplacement(true);
    setSelectedVariant({});
  }

  const requestCover = () => {
    lineItem.is_replacement = false;
    lineItem.is_refund = false;
    lineItem.treated = false;
    lineItem.cover_request = true
    setIsRefund(false);
    setIsExchange(false);
    setIsReplacement(false);
    setCoverRequest(true)
    setSelectedVariant({});
  }

  console.log('parts_request', lineItem.parts_request)

  return (
    // <Grid container spacing={6} alignItems='center' key={Math.random()}>
    //   <Grid item xs={12} sm={12}>
    <Card style={{ marginBottom: '5px' }}>
      <CardContent style={{ paddingBottom: '10px' }}>
        {isExchange && lineItem.treated ?
          <Typography variant='body2'>
            Produit déjà échangé
            </Typography>
          : null}
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <img alt='product variant' src={variant && variant.image.src} height={80} style={{ maxWidth: "100%" }} />
          </Grid>
          <Grid item xs={9}>
            {t(`products:${lineItem.sku}.name`)}
          </Grid>
          {/* <Grid item xs={4}>
            {lineItem.name}
          </Grid> */}
        </Grid>
        {
          (!isRefund && !isExchange && !isReplacement && !coverRequest && !lineItem.parts_request) || (isExchange && lineItem.treated) ?
            (
              <ReturnTypeDialog lineItem={lineItem} setBedspringFeilds={setBedspringFeilds} setIsBedspringFormActive={setIsBedspringFormActive} exchangeProducts={allowedExchangeProducts} handleExchangeVariant={handleExchangeVariant} handleRefundItem={ handleRefundItem} handleReplacement={handleReplacement} requestCover={requestCover}/>
              // <Grid container spacing={1} alignItems='center'>
              //   <Grid item xs={6} style={{ textAlign: 'center' }}>
              //     <ExchangeDialog
              //       lineItem={lineItem}
              //       exchangeProducts={allowedExchangeProducts}
              //       onSubmit={handleExchangeVariant}
              //     />
              //   </Grid>
              //   <Grid item xs={6} style={{ textAlign: 'center' }}>
              //     <RefundDialog
              //       onSubmit={handleRefundItem}
              //     />
              //   </Grid>
              // </Grid>
            )
            :
            (null)
        }
        <LineItemReturnInfo lineItem={lineItem} handleCancel={handleCancel} />
      </CardContent>
    </Card>
    //   </Grid>
    // </Grid>
  )
}