import React from "react";
import { Typography, Grid, Card, CardContent } from "@material-ui/core";

import LineItemReturnInfo from "../../LineItemReturnInfo";

import { useTranslation } from "react-i18next";
import { withStoreContext } from "../../../context/storeFrontContext";

const ReviewOrder = (props) => {
  const { t } = useTranslation();
  const {
    storeContext,
    formValues: { lineItems },
  } = props;

  const isBedSpringProduct = () => {
    if(lineItems && lineItems.length > 0) {
      let isSpringProduct = lineItems.filter(lineItem => lineItem).filter(
        (lineItem) => {
          return (lineItem.title.includes('Sommier') && (lineItem.parts_request || lineItem.is_refund || lineItem.is_replacement))
        }
      );
  
      if (isSpringProduct.length > 0) {
        return true;
      } else {
        return false;
      }
    }
   return false;
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t("return_confirmation_page.title")}
      </Typography>

      <Typography variant="body2" gutterBottom>
        <div dangerouslySetInnerHTML={{ __html: isBedSpringProduct() ? t("return_confirmation_page.bedspring_body") : t("return_confirmation_page.body") }}/>
        {/* {t("return_confirmation_page.body")} */}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {lineItems
            .filter((lineItem) => lineItem && (lineItem.is_exchange || lineItem.is_refund || lineItem.is_replacement || lineItem.cover_request || lineItem.parts_request))
            .map((lineItem) => {
              // console.log("lineItem", lineItem);

              const variant = storeContext.getVariantById(lineItem.variant_id); //_getShopifyVariant(line_item.variant_id);

              return (
                // <ReturnLineItem lineItem={lineItem} />
                <Card style={{ marginBottom: "5px" }}>
                  <CardContent style={{ paddingBottom: "10px" }}>
                    {lineItem.is_exchange && lineItem.treated ? (
                      <Typography variant="body2">
                        Produit déjà échangé
                      </Typography>
                    ) : null}
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={1}></Grid>
                      <Grid item xs={2}>
                        <img
                          alt="product variant"
                          src={variant && variant.image.src}
                          height={80}
                          style={{ maxWidth: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        {t(`products:${lineItem.sku}.name`)}
                      </Grid>
                      {/* <Grid item xs={4}>
            {lineItem.name}
          </Grid> */}
                    </Grid>
                    <LineItemReturnInfo lineItem={lineItem} />
                  </CardContent>
                </Card>
              );
            })}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withStoreContext(ReviewOrder);
