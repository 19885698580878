export const sizeGuideUrl = {
  fr: {
    "lyneup": {
      "male": "https://percko.com/eur/static/017f5d9f825b6befc1ed47e2c4bdb893/58096/150657ce-bf11-4f01-ae01-57d767b7cd8b.webp",
      "female": "https://percko.com/eur/static/b5f043784c4cb1ac15bf13dd938842d7/58096/467f4db6-a782-43b0-9512-08c95ff80e3c.webp"
    },
    "lynefit": {
      "male": "https://percko.com/eur/static/08105da1a1f0deb9324a69844d21ad95/e0562/96c5dc84-2ad2-4088-a1a6-f44568cd8ff4.webp",
      "female": "https://percko.com/eur/static/47b79e601d302a36968b9c601ad943a1/71758/de5da343-b314-4df5-8afb-68570c96d2d1.webp"
    },
    "lynehome": {
      "male": "https://percko.com/eur/static/7ce208fcf2ce580808f87a927d5a55ce/85280/e1edf22e-46bb-4a58-8ee4-4662e8c121fa.webp",
      "female": "https://percko.com/eur/static/3c18a801df7950490f276b8d9b824e7b/22fce/1e21f6fb-b086-4d35-9c8a-65565a7cb03e.webp"
    }
  },
  en: {
    "lyneup": {
      "male": "https://percko.com/eur/static/017f5d9f825b6befc1ed47e2c4bdb893/58096/150657ce-bf11-4f01-ae01-57d767b7cd8b.webp",
      "female": "https://percko.com/eur/static/b5f043784c4cb1ac15bf13dd938842d7/58096/467f4db6-a782-43b0-9512-08c95ff80e3c.webp"
    },
    "lynefit": {
      "male": "https://percko.com/eur/static/08105da1a1f0deb9324a69844d21ad95/e0562/96c5dc84-2ad2-4088-a1a6-f44568cd8ff4.webp",
      "female": "https://percko.com/eur/static/47b79e601d302a36968b9c601ad943a1/71758/de5da343-b314-4df5-8afb-68570c96d2d1.webp"
    },
    "lynehome": {
      "male": "https://percko.com/eur/static/7ce208fcf2ce580808f87a927d5a55ce/85280/e1edf22e-46bb-4a58-8ee4-4662e8c121fa.webp",
      "female": "https://percko.com/eur/static/3c18a801df7950490f276b8d9b824e7b/22fce/1e21f6fb-b086-4d35-9c8a-65565a7cb03e.webp"
    }
  }
}

export const getSizeGuideUrl = (productTitle, gender, lang = 'fr') => {
  lang = lang.split('-')[0].toLowerCase();
  let url = sizeGuideUrl?.[lang]?.[productTitle?.toLowerCase()]?.[gender?.toLowerCase()];
  if (!url) {
    switch (productTitle) {
      case "GILET PRATIQUE - FEMME":
        url = "https://nextcloud.percko.io/index.php/apps/files_sharing/publicpreview/NGadXEtzGAPD8ew?fileId=5580507&file=/Guide%20des%20tailles%20Gilet%20pratique%20-%20Femme.jpg&x=1440&y=900&a=true"
        break;
      case "GILET PRATIQUE - HOMME":
        url = "https://nextcloud.percko.io/index.php/apps/files_sharing/publicpreview/NGadXEtzGAPD8ew?fileId=5580496&file=/Guide%20des%20tailles%20Gilet%20pratique%20-%20Homme.jpg&x=1440&y=900&a=true"
        break;
      case "T-SHIRT DISCRET - FEMME":
        url = "https://nextcloud.percko.io/index.php/apps/files_sharing/publicpreview/NGadXEtzGAPD8ew?fileId=5615796&file=/Guide%20de%20taille%20T-shirt%20discret%20-%20Femme%20(1).jpg&x=1440&y=900&a=true"
        break;
      case "T-SHIRT DISCRET - HOMME":
        url = "https://nextcloud.percko.io/index.php/apps/files_sharing/publicpreview/NGadXEtzGAPD8ew?fileId=5615797&file=/Guide%20de%20taille%20T-shirt%20discret%20-%20Homme%20(1).jpg&x=1440&y=900&a=true"
        break;
      case "T-SHIRT DE SPORT - FEMME":
        url = "https://nextcloud.percko.io/index.php/apps/files_sharing/publicpreview/NGadXEtzGAPD8ew?fileId=5580498&file=/Guide%20des%20tailles%20T-shirt%20de%20sport%20-%20Femme.jpg&x=1440&y=900&a=true"
        break;
      case "T-SHIRT DE SPORT - HOMME":
        url = "https://nextcloud.percko.io/index.php/apps/files_sharing/publicpreview/NGadXEtzGAPD8ew?fileId=5580493&file=/Guide%20des%20tailles%20T-shirt%20de%20sport%20-%20Homme.jpg&x=1440&y=900&a=true"
        break;
      default:
        console.log("No size guide found");
    }
  }

  return url;
}
