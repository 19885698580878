const allowedProtductsExchanges = [
    // EUR STAGING
    {
        productId: 5546348511397, // Lyne UP
    },
    {
        productId: 5546349166757, // Lyne FIT
    },
    {
        productId: 5546349723813, // Lyne PRO
    },
    {
        productId: 7068228878501, // Lyne HOME
    },
    {
        productId: 5629535682725, // Loop
    },
    {
        productId: 5546350215333 // Wavii
    },
    {
        productId: 7896862097573, // Matelas
    },
    {
        productId: 7982317240485, // Matelas Pack
    },
    {
        productId: 7896887787685, // Oreiller
    },
    {
        productId: 8357953372503, // Sommier Bois Massif
    },
    {
        productId: 8357952389463 // Sommier Coffre
    },
    {
        productId: 8357953929559 // Sommier Original
    },
    {
        productId: 8598083240279 // GILET PRATIQUE - FEMME
    },
    {
        productId: 8598090023255 // GILET PRATIQUE - HOMME
    },
    {
        productId: 8598700425559 // T-SHIRT DE SPORT - FEMME
    },
    {
        productId: 8598699508055 // T-SHIRT DE SPORT - HOMME
    },
    {
        productId: 8597932409175 // T-SHIRT DISCRET - FEMME
    },
    {
        productId: 8597975761239 // T-SHIRT DISCRET - HOMME
    },
    {
        productId: 8534343614807 // Surmatelas
    },

    // EUR
    {
        productId: 5427307151511, // Lyne UP
    },
    {
        productId: 5427303186583, // Lyne FIT
    },
    {
        productId: 5427300630679, // Lyne PRO
    },
    {
        productId: 7188985348247, // Lyne HOME
    },
    {
        productId: 5645281099927, // Loop
    },
    {
        productId: 5427298467991 // 5546350215333, // Wavii
    },
    {
        productId: 7791837053079, // Matelas
    },
    {
        productId: 7791843868823, // Matelas Pack
    },
    {
        productId: 7791834661015, // Oreiller
    },
    {
        productId: 8569309757786 // GILET PRATIQUE - FEMME
    },
    {
        productId: 8569335546202 // GILET PRATIQUE - HOMME
    },
    {
        productId: 8569311789402 // T-SHIRT DE SPORT - FEMME
    },
    {
        productId: 8569342525786 // T-SHIRT DE SPORT - HOMME
    },
    {
        productId: 8569313493338 // T-SHIRT DISCRET - FEMME
    },
    {
        productId: 8569353929050 // T-SHIRT DISCRET - HOMME
    },
    {
        productId: 8510606770522 // Surmatelas
    },


    // GBP STAGING
    {
        productId: 5638113853608, // Lyne UP
    },
    {
        productId: 5638114902184, // Lyne FIT
    },
    {
        productId: 5638115426472, // Lyne PRO
    },
    {
        productId: 6893939228852, // Lyne HOME
    },
    {
        productId: 5761485996200, // Loop
    },

    // GBP 
    {
        productId: 5517053624469, // Lyne UP
    },
    {
        productId: 5517051986069, // Lyne FIT
    },
    {
        productId: 5517051003029, // Lyne PRO
    },
    {
        productId: 6989300203711, // Lyne HOME
    },
    {
        productId: 5716071514261, // Loop
    },


]

export const filterExchangeAllowedProducts = (items) => {
    const filtered = items.filter(item => {
        return allowedProtductsExchanges.find(allowedProduct => item.product_id === allowedProduct.productId)
    })
    return filtered;
}