import React, { useEffect } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import axios from 'axios';

import ReactGA from "react-ga";

function ReturnSuccess(props) {
  const { returnData } = props;
  const { t } = useTranslation();

  // const return_adress = {
  //   company_name: t(`return_address_${returnData.source}.company_name`),
  //   address_part1: t(`return_address_${returnData.source}.address_part1`),
  //   address_part2: t(`return_address_${returnData.source}.address_part2`),
  //   zip: t(`return_address_${returnData.source}.zip`),
  //   country: t(`return_address_${returnData.source}.country`),
  // };

  let addressSuffix = '';
  console.log('returnData', returnData);
  // let isNightProduct = returnData.return_line_items.find(
  //   (product) => {
  //     return product.title == "Matelas" || product.title == "Oreiller" || product.title == "1 Matelas + Oreiller"
  //   }
  // );
  // if (isNightProduct) {
  //   addressSuffix = '-night-products';
  // }

  // let isTextileProduct = returnData.return_line_items.find(
  //   (product) => {
  //     return product.title != "Matelas" && product.title != "Oreiller" && product.title != "1 Matelas + Oreiller"
  //   }
  // );

  const store = returnData[0].shopify_store?.split('.')?.[0];
  const return_address =
    `
    ${t(`return_address_${store}.company_name`)} <br/>
    ${t(`return_address_${store}.address_part1`)} <br/>
    ${t(`return_address_${store}.address_part2`) ? t(`return_address_${store}.address_part2`) + '<br/>' : ''}
    ${ t(`return_address_${store}.zip`)} ${ t(`return_address_${store}.city`)} <br/>
    ${t(`return_address_${store}.country`)} <br/>
    `;

  const onDownload = (returnDataItem) => {
    axios({
      method: "get",
      url: returnDataItem.pdf_url,
      responseType: "arraybuffer",
      headers: { 
        'Accept': '*/*',
      }
    })
      .then((response) => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        link.download = `${returnDataItem.id}.pdf`;
    
        document.body.appendChild(link);
    
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);
      })
      .catch((error) => {});

  }

  const getReturnAddress = (returnDataItem) => {
    let isNightProduct = returnDataItem.return_line_items.find(
      (product) => {
        return product.title == "Matelas" || product.title == "Oreiller" || product.title == "1 Matelas + Oreiller"
      }
    );

    if (isNightProduct) {
      addressSuffix = '-night-products';
    }
    
    return `
      ${t(`return_address_${store}${addressSuffix}.company_name`)} <br/>
      ${t(`return_address_${store}${addressSuffix}.address_part1`)} <br/>
      ${t(`return_address_${store}${addressSuffix}.address_part2`) ? t(`return_address_${store}${addressSuffix}.address_part2`) + '<br/>' : ''}
      ${ t(`return_address_${store}${addressSuffix}.zip`)} ${ t(`return_address_${store}${addressSuffix}.city`)} <br/>
      ${t(`return_address_${store}${addressSuffix}.country`)} <br/>
      `;
  }

  const isMattressProduct = (returnDataItem) => {
    let isNightProduct = returnDataItem.return_line_items.find(
      (product) => {
        return product.title == "Matelas" || product.title == "1 Matelas + Oreiller"
      }
    );

    if (isNightProduct) {
      return true;
    } else {
      return false;
    }
  }

  const isBedSpringProduct = (returnDataItem) => {
    if(returnDataItem.return_line_items && returnDataItem.return_line_items.length > 0) {

      let isSpringProduct = returnDataItem.return_line_items.find(
        (product) => {
          return product.title == "Sommier Bois Massif" || product.title == "Sommier Original" || product.title == "Sommier Coffre"
        }
        );
        
        if (isSpringProduct) {
          return true;
        } else {
          return false;
        }
    }
    return false;
  }

  const isPillowProduct = (returnDataItem) => {
    let isPillowProduct = returnDataItem.return_line_items.find(
      (product) => {
        return product.title == "Oreiller"
      }
    );

    if (isPillowProduct) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    ReactGA.event({
      category: 'Return',
      action: 'success',
      label: `${returnData[0]?.id}`,
      value: 1
    });
  }, [returnData[0]?.id])

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {t('thank_you_page.title')}
      </Typography>

      <Typography variant="body2" gutterBottom>
        {t('thank_you_page.nous_vous')}
      </Typography>

      {returnData.map(returnDataItem => (
        <>
          <Typography variant="body2">
          <div dangerouslySetInnerHTML={{
            __html: t(isMattressProduct(returnDataItem) && returnDataItem.cover_request ? 'thank_you_page.body_cover' : isPillowProduct(returnDataItem) && returnDataItem.cover_request ? 'thank_you_page.body_cover' :  isMattressProduct(returnDataItem) ? 'thank_you_page.body_mattress' : isPillowProduct(returnDataItem) ? 'thank_you_page.body_pillow' : isBedSpringProduct(returnDataItem) ? 'thank_you_page.body_bedspring' : 'thank_you_page.body',
              {
                id: returnDataItem?.id,
                return_address: getReturnAddress(returnDataItem)
              })
          }} />
          </Typography><Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button onClick={() => {onDownload(returnDataItem)}} color="primary" variant="contained">
              {t('thank_you_page.download_return_receipt')}
            </Button>
          </Grid>
          <br/><br/>
          <Grid item xs={12} style={{ minHeight: "400px" }}>
            <object data={returnDataItem.pdf_url} type="application/pdf" width="100%" height="100%">
              <p>Link <a href={returnDataItem.pdf_url}>to the PDF!</a></p>
            </object>
          </Grid>
          <br/><br/>
        </Grid>
        </>
      ))}
    </React.Fragment>
  );
}

export default ReturnSuccess;
