import React from 'react';
import { 
    Grid,
    Card, 
    CardContent,
    IconButton,
} 
from '@material-ui/core';

import {
    SyncAlt as SyncAltIcon,
    CreditCard as CreditCardIcon,
    HighlightOff as HighlightOffIcon,
    ArrowRightAlt as ArrowRightIcon
} from '@material-ui/icons';

import { useTranslation } from 'react-i18next';

export default function LineItemReturnInfo(props) {
    const { t } = useTranslation(['translation', 'products']);
    const { 
        handleCancel,
        lineItem,
    } = props;

    console.log('LineItemReturnInfo', lineItem)
    return (
        lineItem.exchange_variant && lineItem.exchange_variant.id ?
        (
        <Card>
            <CardContent>
            {/* <Divider /> */}
            <Grid container spacing={1} alignItems='center' >
                <Grid item xs={1}>
                    <SyncAltIcon />
                </Grid>
                <Grid item xs={2}>
                    <img alt="product variant" src={lineItem.exchange_variant.image.src} height={50} style={{ maxWidth: "100%" }}/>
                </Grid>
                <Grid item xs={8}>
                    {t(`products:${lineItem.exchange_variant.sku}.name`)}
                </Grid>            
                <Grid item xs={1}>
                    {handleCancel ? 
                        (
                        <IconButton aria-label="delete" onClick={handleCancel} color="secondary">
                            <HighlightOffIcon />
                        </IconButton>
                        ) 
                        : 
                        (null)
                    }
                </Grid>
            </Grid>
            </CardContent>
        </Card>
        )
        :
        lineItem.is_refund ? 
        (
            <Card>
            <CardContent>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item xs={1}>
                        <CreditCardIcon />
                    </Grid>
                    <Grid item xs={10}>
                        {t('refund')}
                    </Grid>

                    <Grid item xs={1}>
                        {handleCancel ? 
                            (
                            <IconButton aria-label="delete" onClick={handleCancel} color="secondary">
                                <HighlightOffIcon />
                            </IconButton>
                            ) 
                            : 
                            (null)
                        }
                    </Grid>
                </Grid>
                </CardContent>
            </Card>
        )
        : lineItem.is_replacement ? 
        (
            <Card>
            <CardContent>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item xs={1}>
                        <ArrowRightIcon />
                    </Grid>
                    <Grid item xs={10}>
                        {t('replacement')}
                    </Grid>

                    <Grid item xs={1}>
                        {handleCancel ? 
                            (
                            <IconButton aria-label="delete" onClick={handleCancel} color="secondary">
                                <HighlightOffIcon />
                            </IconButton>
                            ) 
                            : 
                            (null)
                        }
                    </Grid>
                </Grid>
                </CardContent>
            </Card>
        ) : lineItem.cover_request ? 
        (
            <Card>
            <CardContent>
                <Grid container spacing={3} alignItems='center'>
 
                    <Grid item xs={10}>
                        {t('cover')}
                    </Grid>
                    <Grid item xs={1}>
                        {handleCancel ? 
                            (
                            <IconButton aria-label="delete" onClick={handleCancel} color="secondary">
                                <HighlightOffIcon />
                            </IconButton>
                            ) 
                            : 
                            (null)
                        }
                    </Grid>
                </Grid>
                </CardContent>
            </Card>
        ) : lineItem.parts_request ? (
            <Card>
            <CardContent>
                <Grid container spacing={3} alignItems='center'>
 
                    <Grid item xs={10}>
                        {t('parts')}
                    </Grid>
                    <Grid item xs={1}>
                        {handleCancel ? 
                            (
                            <IconButton aria-label="delete" onClick={handleCancel} color="secondary">
                                <HighlightOffIcon />
                            </IconButton>
                            ) 
                            : 
                            (null)
                        }
                    </Grid>
                </Grid>
                </CardContent>
            </Card>
        ) :
        (null)
    )
}